<template>
    <PageReconciliationApproved pageType="fact" />
</template>

<script>
import PageReconciliationApproved from './PageReconciliationApproved.vue';
export default {
    name: 'PageReconciliationFact',
    components: {PageReconciliationApproved}
}
</script>